import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import numeral from "numeral";
import { getOneAvenant } from "../../../services/gestion_production/avenantsService";
import PreviewContentWrapper from "../previewSections/partials/PreviewContentWrapper";
import PreviewTemplateLayout from "../previewSections/partials/PreviewTemplateLayout";
import { findOne } from "../../../services/gestion_production/policesService";

function RetraitGarantiesPreview({ souscription, avenant_uuid }) {
  const [avenant, setAvenant] = useState(null);
  const [souscriptionWithAvenant, setSouscriptionWithAvenant] = useState(null);

  const fetchSouscription = async () => {
    try {
      const response = await findOne(souscription.uuid, avenant_uuid);
      setSouscriptionWithAvenant(response?.data);
    } catch (error) {}
  };

  const fetchAvenant = async () => {
    try {
      const response = await getOneAvenant(avenant_uuid);
      setAvenant(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (avenant_uuid && souscription) {
      fetchAvenant();
      fetchSouscription();
    }
  }, [avenant_uuid, souscription]);

  const calculeCoefficient = (avenant) => {
    try {
      return (
        (dayjs(souscriptionWithAvenant?.conditions_particulieres?.date_echeance)
          .add(1, "minutes")
          .subtract(1, "day")
          .diff(
            dayjs(avenant?.date_avenant)
              .hour(0)
              .minute(0)
              .second(0)
              .add(1, "day"),
            "days"
          ) +
          1) /
        dayjs(souscriptionWithAvenant?.conditions_particulieres?.date_echeance)
          .add(1, "day")
          .diff(
            dayjs(
              souscriptionWithAvenant?.conditions_particulieres?.date_effet
            ),
            "days"
          )
      ).toFixed(4);
    } catch (error) {
      return 0;
    }
  };

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <PageView size="A4" style={[styles.page, { padding: 0 }]}>
          <PreviewTemplateLayout souscription={souscription} />
          <PreviewContentWrapper souscription={souscription}>
            <View
              style={{
                marginTop: 10,
                backgroundColor: "#dce1e6",
                padding: 5,
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                Avenant de retrait de garantie(s)
              </Text>
            </View>

            <View
              style={{
                marginTop: 20,
                flexDirection: "column",
                gap: 2,
                padding: 10,
                borderColor: "#CCC",
                borderWidth: 1,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  fontWeight: "bold",
                  gap: 10,
                  justifyContent: "space-between",
                }}
              >
                <Text>POLICE :</Text>
                <Text style={{ paddingLeft: 19 }}>
                  {souscriptionWithAvenant?.souscription_polices
                    ?.numero_police || "N/A"}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  fontWeight: "bold",
                  gap: 10,
                  justifyContent: "space-between",
                }}
              >
                <Text>ASSURE :</Text>
                <Text style={{ paddingLeft: 15 }}>
                  {souscriptionWithAvenant?.souscripteur_assure?.assures
                    .nom_assure || "N/A"}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  fontWeight: "bold",
                  gap: 10,
                  justifyContent: "space-between",
                }}
              >
                <Text>EFFET :</Text>
                <Text style={{ paddingLeft: 25, fontWeight: "bold" }}>
                  {dayjs(avenant?.date_avenant)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .add(1, "day")
                    .format("DD/MM/YYYY")}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  fontWeight: "bold",
                  gap: 10,
                  justifyContent: "space-between",
                }}
              >
                <Text>EXPIRATION :</Text>
                <Text>
                  {dayjs(
                    souscriptionWithAvenant?.conditions_particulieres
                      ?.date_echeance
                  )
                    .add(1, "minutes")
                    .subtract(1, "day")
                    .format("DD/MM/YYYY")}
                </Text>
              </View>
            </View>

            <View style={{ marginTop: 25 }}>
              <Text>
                A la demande de l'assuré(e), et d'un commun accord entre les
                parties, il a été convenu qu'à compter du{" "}
                <Text>
                  {dayjs(avenant?.date_avenant)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .add(1, "day")
                    .format("DD/MM/YYYY")}
                </Text>{" "}
                {avenant?.meta_data?.removed_garanties?.length > 1
                  ? " les garanties : "
                  : " la garantie : "}{" "}
                {avenant?.meta_data?.removed_garanties?.map((garantie) => (
                  <Text key={garantie.uuid}>
                    {
                      garantie?.pool_directives_tarifaires_has_listes_garanties
                        ?.liste_garanties?.name
                    }
                    {" , "}
                  </Text>
                ))}
                {avenant?.meta_data?.removed_garanties?.length > 1
                  ? " sont retirées "
                  : " est retirée "}
                aux autres garanties du véhicule ci-après faisant partie du
                présent contrat.
              </Text>
            </View>

            <View style={{ marginTop: 10 }}>
              <Text style={{ fontWeight: "bold", fontSize: 10 }}>
                Marque :{" "}
                {
                  souscriptionWithAvenant?.souscription_identite_vehicule
                    ?.marque_vehicule
                }
                {" , "}
                Immatriculation :{" "}
                {
                  souscriptionWithAvenant?.souscription_identite_vehicule
                    ?.immatriculation_vehicule
                }
              </Text>
              <Text>
                Valeur vénale du véhicule :{" "}
                {numeral(
                  souscriptionWithAvenant?.souscription_identite_vehicule
                    ?.valeur_venale
                ).format()}{" "}
                FCFA
              </Text>
            </View>

            <View style={{ marginTop: 10 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 10,
                  textDecoration: "underline",
                }}
              >
                Garanties souscrites :
              </Text>
              <View style={{ marginTop: 10 }}>
                <Text>
                  {souscriptionWithAvenant?.souscription_garanties
                    ?.filter((garantie) => {
                      const sommeRisques =
                        garantie.souscription_garanties_risques?.reduce(
                          (acc, risque) => acc + (Number(risque.value) || 0),
                          0
                        );
                      return (
                        sommeRisques > 0 ||
                        garantie.company_has_liste_garanties
                          ?.pool_directives_tarifaires_has_listes_garanties
                          .is_required === 1
                      );
                    })
                    ?.map((garantie) => (
                      <Text key={garantie.uuid}>
                        {
                          garantie.company_has_liste_garanties
                            ?.pool_directives_tarifaires_has_listes_garanties
                            ?.liste_garanties?.name
                        }
                        {" , "}
                      </Text>
                    ))}
                </Text>
              </View>
            </View>

            <View style={{ marginTop: 10 }}>
              <Text>
                Par ce fait, la nouvelle Prime Nette Annuelle est désormais
                présentée, ainsi qu’il suit :
              </Text>
              <View
                style={{
                  marginTop: 10,
                  borderColor: "#CCC",
                  borderWidth: 1,
                  padding: 10,
                }}
              >
                <View>
                  <View style={{ flexDirection: "row", paddingTop: 5 }}>
                    <Text style={{ width: "50%" }}>Ancienne Prime Nette</Text>
                    <Text style={{ width: "50%", textAlign: "right" }}>
                      {numeral(
                        souscriptionWithAvenant?.prime_nette_totale -
                          souscriptionWithAvenant?.prime_nette_iac
                      ).format()}{" "}
                      FCFA
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row", paddingTop: 5 }}>
                    <Text style={{ width: "50%" }}>Nouvelle Prime Nette</Text>
                    <Text style={{ width: "50%", textAlign: "right" }}>
                      {numeral(
                        (souscriptionWithAvenant?.prime_nette_totale -
                          souscriptionWithAvenant?.prime_nette_iac) -
                          avenant?.bills?.souscription_reimbursements?.amount /
                            calculeCoefficient(avenant)
                      ).format()}{" "}
                      FCFA
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row", paddingTop: 5 }}>
                    <Text style={{ width: "50%" }}>Différence Nette</Text>
                    <Text style={{ width: "50%", textAlign: "right" }}>
                      {numeral(
                        avenant?.bills?.souscription_reimbursements?.amount /
                          calculeCoefficient(avenant)
                      ).format()}{" "}
                      FCFA
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ marginTop: 20, marginBottom: 10 }}>
              <Text>
                En conséquence, l'assuré recevra à la signature du présent
                Avenant, une ristourne de prime égale à la somme de{" "}
                {numeral(
                  avenant?.bills?.souscription_reimbursements?.amount -
                    avenant?.bills?.amount
                ).format()}{" "}
                FCFA ainsi décomptée :
              </Text>

              <View
                style={{
                  marginTop: 10,
                  borderColor: "#CCC",
                  borderWidth: 1,
                  padding: 10,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    borderBottom: 1,
                    padding: 5,
                    marginBottom: 5,
                  }}
                >
                  <Text style={{ width: "70%", fontWeight: "bold" }}>
                    Désignation
                  </Text>
                  <Text
                    style={{
                      width: "30%",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    Montant
                  </Text>
                </View>

                <View style={{ flexDirection: "row" }}>
                  <Text style={{ width: "70%" }}>Prime nette à ristourner</Text>
                  <Text style={{ width: "30%", textAlign: "right" }}>
                    {numeral(
                      avenant?.bills?.souscription_reimbursements?.amount
                    ).format()}{" "}
                    FCFA
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ width: "70%" }}>Coût de l'avenant</Text>
                  <Text style={{ width: "30%", textAlign: "right" }}>
                    - {numeral(avenant?.bills?.amount).format()} FCFA
                  </Text>
                </View>

                <View
                  style={{ flexDirection: "row", fontSize: 12, paddingTop: 4 }}
                >
                  <Text style={{ width: "70%", fontWeight: "bold" }}>
                    Prime Totale à ristourner
                  </Text>
                  <Text
                    style={{
                      width: "30%",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    {numeral(
                      avenant?.bills?.souscription_reimbursements?.amount -
                        avenant?.bills?.amount
                    ).format()}{" "}
                    FCFA
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                marginTop: 10,
                textAlign: "center",
                width: "100%",
                flexDirection: "row",
                gap: 5,
              }}
            >
              <Text style={{ fontWeight: "bold", textTransform: "center" }}>
                Pour la période allant du{" "}
                {dayjs(avenant?.date_avenant)
                  .hour(0)
                  .minute(0)
                  .second(0)
                  .add(1, "day")
                  .format("DD/MM/YYYY")}{" "}
                au{" "}
                {dayjs(
                  souscriptionWithAvenant?.conditions_particulieres
                    ?.date_echeance
                )
                  .add(1, "minutes")
                  .subtract(1, "day")
                  .format("DD/MM/YYYY")}
              </Text>
              <Text>
                soit pour{" "}
                {dayjs(
                  souscriptionWithAvenant?.conditions_particulieres
                    ?.date_echeance
                )
                  .add(1, "minutes")
                  .subtract(1, "day")
                  .diff(
                    dayjs(avenant?.date_avenant)
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .add(1, "day"),
                    "days"
                  ) + 1}{" "}
                jours
              </Text>
              <Text>coefficient : {calculeCoefficient(avenant)}</Text>
            </View>
            <View style={{ marginTop: 30, marginBottom: 30 }}>
              <Text style={{ fontStyle: "italic", textAlign: "right" }}>
                Fait à _______________________, le{" "}
                {dayjs(avenant?.date_avenant).format("DD/MM/YYYY")}
              </Text>
            </View>
            <View
              style={{
                marginTop: 20,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "45%" }}>
                <Text
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textDecoration: "underline",
                  }}
                >
                  L'assuré
                </Text>
              </View>
              <View style={{ width: "45%" }}>
                <Text
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textDecoration: "underline",
                    textAlign: "right",
                  }}
                >
                  Pour la Compagnie
                </Text>
              </View>
            </View>
          </PreviewContentWrapper>
        </PageView>
      </Document>
    </PDFViewer>
  );
}

export default RetraitGarantiesPreview;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 30,
    fontSize: 10,
    lineHeight: 1.5,
  },
  section: {
    padding: 20,
  },
  sectionItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 3,
    fontSize: 8,
  },
});
